<template>
  <div>
    <div 
      v-show="visibleProperties.length === 0"
      class="text-h5 my-5"
    >
      {{ $t('Properties') }}
    </div>

    <component          
      :is="item.component"
      v-for="item in pc.items"
      v-show="visibleProperties.length == 0 ? true : visibleProperties.includes(item.propertyName)"
      :key="item.propertyId"
      :model="item"
    >
    </component>
  </div>
</template>
<script>
import { PropertyCollection } from '../../model/property/PropertyCollection'
import HdTextField from './field/HdTextField'
import HdNumberField from './field/HdNumberField'
import HdOptionsField from './field/HdOptionsField'
import HdMultipleOptionsField from './field/HdMultipleOptionsField'
import HdToggleField from './field/HdToggleField'
import HdTriStateField from './field/HdTriStateField'
import { propertyType } from '../../util/enum'
import HdImpersonateField from './field/HdImpersonateField'

import { mapGetters } from 'vuex' 
// import { propertyMerger } from '../../util/tools'

export default {
	name: 'PropertyList',	
	components: {
		HdTextField,
		HdOptionsField,
		HdMultipleOptionsField,
    HdToggleField,
    HdNumberField,
    HdTriStateField,
    HdImpersonateField,
	},
	props: {
    selectedProperties: {
      type: Array,
      required: false,
      default() {
        return []
      },
      
    },
    type: {
      type: Number,
      required: false,
      default() {
        return 0
      }
    },
    visibleProperties: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
	},
  data() {
    return {
      pc: new PropertyCollection([])
    }
  },  
  computed: {
    ...mapGetters('user', [
      'getUserContextProperties', 
      'getCompanyContextProperties', 
      'getEndpointContextProperties'
    ]),      
  },  

  watch: {
    selectedProperties: {
      deep: true,
      handler() {
  
        let contextProps = []
        
        switch(this.type) {
          case propertyType.company:
            contextProps = this.getCompanyContextProperties
            break
          case propertyType.endpoint:
            contextProps = this.getEndpointContextProperties
            break        
          default:
            contextProps = this.getUserContextProperties
        }

        if(this.selectedProperties.length) {            
          // let administratedProps = propertyMerger(this.selectedProperties, contextProps)
          this.pc.setItems(this.selectedProperties)               
        } else {
          this.pc.setItems(contextProps)
        }     
      }
    }
  },

  methods: {
    /**
     * 
     * @returns {Property[]} 
     */
    getValues() {
      return this.pc.items
    }
  }
  
}
</script>

<style scoped>

</style>